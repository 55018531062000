export const Scores= {
  "navi-fnat": [
    [
      13,
      9
    ]
  ],
  "vita-gl": [
    [
      13,
      8
    ]
  ],
  "mouz-nemi": [
    [
      13,
      3
    ]
  ],
  "faze-c9": [
    [
      13,
      8
    ]
  ],
  "saw-sinn": [
    [
      11,
      13
    ]
  ],
  "falc-eclo": [
    [
      16,
      13
    ]
  ],
  "sang-rebe": [
    [
      13,
      8
    ]
  ],
  "betb-unit": [
    [
      13,
      4
    ]
  ],
  "navi-sinn": [
    [
      13,
      5
    ]
  ],
  "vita-betb": [
    [
      13,
      3
    ]
  ],
  "mouz-sang": [
    [
      13,
      3
    ]
  ],
  "faze-falc": [
    [
      16,
      12
    ]
  ],
  "gl-eclo": [
    [
      11,
      13
    ]
  ],
  "fnat-rebe": [
    [
      13,
      3
    ]
  ],
  "nemi-c9": [
    [
      5,
      13
    ]
  ],
  "saw-unit": [
    [
      13,
      8
    ]
  ],
  "navi-mouz": [
    [
      13,
      4
    ],
    [
      8,
      13
    ],
    [
      7,
      13
    ]
  ],
  "faze-vita": [
    [
      5,
      13
    ],
    [
      11,
      13
    ]
  ],
  "sinn-eclo": [
    [
      13,
      8
    ]
  ],
  "sang-c9": [
    [
      2,
      13
    ]
  ],
  "falc-saw": [
    [
      5,
      13
    ]
  ],
  "betb-fnat": [
    [
      11,
      13
    ]
  ],
  "nemi-rebe": [
    [
      13,
      8
    ],
    [
      23,
      25
    ],
    [
      19,
      17
    ]
  ],
  "gl-unit": [
    [
      5,
      13
    ],
    [
      13,
      7
    ],
    [
      13,
      11
    ]
  ],
  "sinn-c9": [
    [
      7,
      13
    ],
    [
      13,
      9
    ],
    [
      11,
      13
    ]
  ],
  "navi-saw": [
    [
      13,
      10
    ],
    [
      13,
      2
    ]
  ],
  "faze-fnat": [
    [
      13,
      5
    ],
    [
      13,
      8
    ]
  ],
  "sang-eclo": [
    [
      10,
      13
    ],
    [
      6,
      13
    ]
  ],
  "betb-nemi": [
    [
      13,
      8
    ],
    [
      13,
      8
    ]
  ],
  "falc-gl": [
    [
      6,
      13
    ],
    [
      13,
      7
    ],
    [
      7,
      13
    ]
  ],
  "betb-saw": [
    [
      13,
      9
    ],
    [
      13,
      9
    ]
  ],
  "fnat-eclo": [
    [
      13,
      4
    ],
    [
      11,
      13
    ],
    [
      19,
      17
    ]
  ],
  "sinn-gl": [
    [
      8,
      13
    ],
    [
      13,
      4
    ],
    [
      9,
      13
    ]
  ],
  "betb-gl#1": [
    [
      16,
      12
    ],
    [
      5,
      13
    ],
    [
      9,
      13
    ]
  ],
  "faze-c9#1": [
    [
      13,
      6
    ],
    [
      7,
      13
    ],
    [
      13,
      6
    ]
  ],
  "g2-nip": [
    [
      13,
      7
    ]
  ],
  "spir-b8": [
    [
      13,
      1
    ]
  ],
  "ef-auro": [
    [
      13,
      8
    ]
  ],
  "vp-pass": [
    [
      14,
      16
    ]
  ],
  "hero-pari": [
    [
      13,
      8
    ]
  ],
  "astr-9pan": [
    [
      9,
      13
    ]
  ],
  "big-sash": [
    [
      13,
      9
    ]
  ],
  "3dma-tsm": [
    [
      13,
      11
    ]
  ],
  "spir-pass": [
    [
      11,
      13
    ]
  ],
  "g2-9pan": [
    [
      16,
      13
    ]
  ],
  "ef-3dma": [
    [
      16,
      19
    ]
  ],
  "hero-big": [
    [
      8,
      13
    ]
  ],
  "nip-pari": [
    [
      16,
      12
    ]
  ],
  "b8-auro": [
    [
      9,
      13
    ]
  ],
  "astr-sash": [
    [
      11,
      13
    ]
  ],
  "vp-tsm": [
    [
      13,
      10
    ]
  ],
  "big-pass": [
    [
      9,
      13
    ],
    [
      13,
      5
    ],
    [
      13,
      9
    ]
  ],
  "g2-3dma": [
    [
      13,
      5
    ],
    [
      6,
      13
    ],
    [
      11,
      13
    ]
  ],
  "spir-auro": [
    [
      13,
      7
    ]
  ],
  "vp-9pan": [
    [
      13,
      10
    ]
  ],
  "ef-sash": [
    [
      7,
      13
    ]
  ],
  "hero-nip": [
    [
      13,
      5
    ]
  ],
  "tsm-pari": [
    [
      5,
      13
    ],
    [
      13,
      9
    ],
    [
      13,
      4
    ]
  ],
  "astr-b8": [
    [
      11,
      13
    ],
    [
      13,
      11
    ],
    [
      13,
      9
    ]
  ],
  "sash-vp": [
    [
      13,
      7
    ],
    [
      8,
      13
    ],
    [
      4,
      13
    ]
  ],
  "pass-hero": [
    [
      13,
      9
    ],
    [
      7,
      13
    ],
    [
      7,
      13
    ]
  ],
  "g2-spir": [
    [
      13,
      9
    ],
    [
      5,
      13
    ],
    [
      16,
      14
    ]
  ],
  "9pan-auro": [
    [
      13,
      9
    ],
    [
      13,
      3
    ]
  ],
  "tsm-nip": [
    [
      3,
      13
    ],
    [
      13,
      16
    ]
  ],
  "ef-astr": [
    [
      13,
      6
    ],
    [
      8,
      13
    ],
    [
      11,
      13
    ]
  ],
  "9pan-nip": [
    [
      10,
      13
    ],
    [
      13,
      10
    ],
    [
      8,
      13
    ]
  ],
  "pass-astr": [
    [
      13,
      9
    ],
    [
      13,
      11
    ]
  ],
  "sash-spir": [
    [
      8,
      13
    ],
    [
      6,
      13
    ]
  ],
  "nip-spir#1": [
    [
      5,
      13
    ],
    [
      11,
      13
    ]
  ],
  "furi-lega": [
    [
      17,
      19
    ]
  ],
  "liqu-wild": [
    [
      12,
      16
    ]
  ],
  "pain-noun": [
    [
      13,
      5
    ]
  ],
  "col-best": [
    [
      13,
      7
    ]
  ],
  "9z-red": [
    [
      13,
      7
    ]
  ],
  "m80-boss": [
    [
      7,
      13
    ]
  ],
  "mibr-kr": [
    [
      13,
      5
    ]
  ],
  "impe-case": [
    [
      13,
      5
    ]
  ],
  "9z-lega": [
    [
      13,
      3
    ]
  ],
  "pain-wild": [
    [
      10,
      13
    ]
  ],
  "col-boss": [
    [
      16,
      12
    ]
  ],
  "mibr-impe": [
    [
      13,
      9
    ]
  ],
  "best-noun": [
    [
      13,
      6
    ]
  ],
  "furi-case": [
    [
      13,
      5
    ]
  ],
  "m80-red": [
    [
      16,
      13
    ]
  ],
  "liqu-kr": [
    [
      13,
      2
    ]
  ],
  "wild-9z": [
    [
      16,
      14
    ],
    [
      13,
      6
    ]
  ],
  "col-mibr": [
    [
      9,
      13
    ],
    [
      11,
      13
    ]
  ],
  "lega-m80": [
    [
      5,
      13
    ]
  ],
  "boss-furi": [
    [
      1,
      13
    ]
  ],
  "liqu-best": [
    [
      13,
      4
    ]
  ],
  "pain-impe": [
    [
      13,
      5
    ]
  ],
  "kr-noun": [
    [
      7,
      13
    ],
    [
      13,
      4
    ],
    [
      13,
      4
    ]
  ],
  "red-case": [
    [
      4,
      13
    ],
    [
      13,
      8
    ],
    [
      13,
      6
    ]
  ],
  "9z-pain": [
    [
      11,
      13
    ],
    [
      0,
      13
    ]
  ],
  "col-m80": [
    [
      13,
      8
    ],
    [
      13,
      9
    ]
  ],
  "liqu-furi": [
    [
      13,
      3
    ],
    [
      3,
      13
    ],
    [
      13,
      7
    ]
  ],
  "boss-best": [
    [
      13,
      9
    ],
    [
      4,
      13
    ],
    [
      6,
      13
    ]
  ],
  "impe-kr": [
    [
      13,
      9
    ],
    [
      13,
      7
    ]
  ],
  "lega-red": [
    [
      7,
      13
    ],
    [
      6,
      13
    ]
  ],
  "best-m80": [
    [
      7,
      13
    ],
    [
      13,
      4
    ],
    [
      17,
      19
    ]
  ],
  "impe-red": [
    [
      13,
      10
    ],
    [
      19,
      17
    ]
  ],
  "9z-furi": [
    [
      8,
      13
    ],
    [
      10,
      13
    ]
  ],
  "m80-furi#1": [
    [
      8,
      13
    ],
    [
      8,
      13
    ]
  ],
  "mong-ae#1": [
    [
      13,
      8
    ]
  ],
  "dril-lvg#1": [
    [
      13,
      11
    ]
  ],
  "mong-dril#1": [
    [
      11,
      13
    ],
    [
      13,
      2
    ],
    [
      13,
      5
    ]
  ],
  "ra-gr#1": [
    [
      13,
      4
    ]
  ],
  "talo-fly#1": [
    [
      13,
      9
    ]
  ],
  "ra-talo#1": [
    [
      13,
      8
    ],
    [
      13,
      8
    ]
  ],
  "ae-lvg#1": [
    [
      10,
      13
    ],
    [
      13,
      9
    ],
    [
      4,
      13
    ]
  ],
  "talo-lvg#1": [
    [
      10,
      13
    ],
    [
      6,
      13
    ]
  ],
  "gr-fly#1": [
    [
      8,
      13
    ],
    [
      8,
      13
    ]
  ],
  "dril-fly#1": [
    [
      9,
      13
    ],
    [
      13,
      2
    ],
    [
      19,
      22
    ]
  ],
  "lvg-fly#1": [
    [
      2,
      13
    ],
    [
      8,
      13
    ]
  ]
}
