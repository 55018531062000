export const Scores = {"0": {"furi-gl": [[8, 13]],
    "vp-mibr": [[7, 13]],
    "liqu-c9": [[13, 10]],
    "col-fly": [[6, 13]],
    "big-pass": [[13, 9]],
    "fnat-wild": [[11, 13]],
    "mong-ra": [[13, 2]],
    "pain-impe": [[13, 5]],
    "big-fly": [[5, 13]],
    "mong-mibr": [[13, 6]],
    "liqu-wild": [[13, 10]],
    "pain-gl": [[10, 13]],
    "fnat-c9": [[5, 13]],
    "col-pass": [[9, 13]],
    "vp-ra": [[13, 4]],
    "furi-impe": [[13, 11]],
    "liqu-fly": [[5, 13], [13, 9], [13, 1]],
    "gl-mong": [[5, 13], [2, 13]],
    "big-vp": [[13, 11]],
    "mibr-pass": [[5, 13]],
    "furi-wild": [[16, 14]],
    "pain-c9": [[13, 11]],
    "ra-fnat": [[10, 13], [13, 10], [13, 4]],
    "col-impe": [[13, 4], [13, 5]],
    "big-furi": [[13, 5], [11, 13], [3, 13]],
    "fly-pain": [[14, 16], [6, 13]],
    "gl-pass": [[13, 6], [5, 13], [13, 9]],
    "mibr-ra": [[13, 9], [11, 13], [13, 4]],
    "c9-col": [[13, 16], [8, 13]],
    "wild-vp": [[10, 13], [13, 7], [13, 8]],
    "big-col": [[5, 13], [13, 9], [13, 9]],
    "fly-mibr": [[4, 13], [5, 13]],
    "pass-wild": [[6, 13], [13, 6], [13, 16]]}, "1": {"g2-mong": [[10, 13]],
    "navi-liqu": [[13, 10]],
    "vita-gl": [[13, 7]],
    "spir-furi": [[6, 13]],
    "mouz-pain": [[13, 6]],
    "faze-wild": [[13, 10]],
    "hero-big": [[13, 7]],
    "3dma-mibr": [[11, 13]],
    "vita-furi": [[16, 13]],
    "mouz-mong": [[13, 16]],
    "navi-mibr": [[11, 13]],
    "faze-hero": [[8, 13]],
    "liqu-gl": [[13, 10]],
    "spir-wild": [[13, 6]],
    "g2-big": [[13, 5]],
    "3dma-pain": [[13, 11]],
    "mibr-vita": [[3, 13], [5, 13]],
    "mong-hero": [[8, 13], [19, 17], [13, 6]],
    "navi-spir": [[2, 13]],
    "furi-liqu": [[9, 13]],
    "g2-3dma": [[13, 8]],
    "mouz-faze": [[10, 13]],
    "gl-wild": [[10, 13], [13, 10], [13, 5]],
    "big-pain": [[10, 13], [13, 11], [6, 13]],
    "g2-faze": [[13, 4], [13, 3]],
    "mibr-liqu": [[2, 13], [13, 10], [5, 13]],
    "hero-spir": [[14, 16], [13, 10], [7, 13]],
    "mouz-3dma": [[13, 11], [13, 11]],
    "navi-gl": [[13, 6], [13, 9]],
    "furi-pain": [[11, 13], [13, 9], [13, 11]],
    "mibr-mouz": [[5, 13], [6, 13]],
    "navi-hero": [[3, 13], [13, 5], [9, 13]],
    "furi-faze": [[13, 6], [9, 13], [10, 13]]}, "2": {"mong-mouz": [[14, 16], [9, 13]],
    "liqu-spir": [[9, 13], [11, 13]],
    "mouz-spir": [[13, 7], [4, 13], [7, 13]],
    "g2-hero": [[6, 13], [13, 9], [16, 13]],
    "faze-vita": [[6, 13], [13, 8], [13, 7]],
    "g2-faze": [[6, 13], [14, 16]],
    "spir-faze": [[13, 8], [6, 13], [13, 11]]}}
