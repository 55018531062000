export const Scores = {"ence-koi": [[11, 13]],
  "sang-1win": [[13, 7]],
  "blee-tsm": [[13, 8]],
  "b8-sinn": [[3, 13]],
  "gl-rhyn": [[13, 16]],
  "eclo-bc.g": [[16, 19]],
  "pari-ente": [[13, 6]],
  "mont-unit": [[13, 6]],
  "sang-bc.g": [[16, 14]],
  "blee-rhyn": [[13, 11]],
  "pari-sinn": [[3, 13]],
  "mont-koi": [[9, 13]],
  "gl-tsm": [[13, 3]],
  "eclo-1win": [[13, 11]],
  "ence-unit": [[7, 13]],
  "b8-ente": [[13, 7]],
  "sinn-koi": [[13, 4], [13, 3]],
  "sang-blee": [[13, 10], [13, 11]],
  "mont-eclo": [[7, 13]],
  "rhyn-unit": [[11, 13]],
  "bc.g-gl": [[11, 13]],
  "b8-pari": [[10, 13]],
  "ence-ente": [[13, 7], [13, 10]],
  "1win-tsm": [[9, 13], [6, 13]],
  "blee-eclo": [[10, 13], [13, 9], [7, 13]],
  "koi-unit": [[13, 11], [17, 19], [5, 13]],
  "pari-gl": [[13, 4], [8, 13], [13, 9]],
  "bc.g-tsm": [[6, 13], [10, 13]],
  "mont-ence": [[13, 1], [11, 13], [13, 7]],
  "rhyn-b8": [[7, 13], [5, 13]],
  "blee-gl": [[12, 16], [14, 16]],
  "koi-tsm": [[9, 13], [11, 13]],
  "mont-b8": [[11, 13], [1, 13]],
  "big-pass": [[3, 13]],
  "spee-c9": [[7, 13]],
  "saw-og": [[13, 7]],
  "nemi-arcr": [[13, 11]],
  "z10-rebe": [[11, 13]],
  "amka-perm": [[6, 13]],
  "sash-qian": [[5, 13]],
  "9pan-insi": [[13, 4]],
  "nemi-perm": [[7, 13]],
  "pass-c9": [[10, 13]],
  "saw-qian": [[13, 4]],
  "9pan-rebe": [[13, 5]],
  "spee-arcr": [[7, 13]],
  "z10-og": [[11, 13]],
  "big-insi": [[13, 5]],
  "amka-sash": [[7, 13]],
  "saw-perm": [[13, 6], [13, 11]],
  "9pan-c9": [[11, 13], [13, 1], [11, 13]],
  "nemi-sash": [[13, 11]],
  "pass-arcr": [[19, 17]],
  "qian-big": [[8, 13]],
  "og-rebe": [[13, 8]],
  "spee-z10": [[16, 12], [7, 13], [13, 11]],
  "amka-insi": [[16, 13], [8, 13], [9, 13]],
  "pass-og": [[13, 7], [11, 13], [13, 8]],
  "9pan-nemi": [[16, 12], [13, 9]],
  "perm-big": [[13, 11], [13, 16], [3, 13]],
  "qian-insi": [[11, 13], [6, 13]],
  "arcr-sash": [[13, 11], [2, 13], [11, 13]],
  "spee-rebe": [[13, 10], [8, 13], [13, 16]],
  "og-sash": [[6, 13], [8, 13]],
  "perm-rebe": [[10, 13], [7, 13]],
  "nemi-insi": [[16, 13], [9, 13], [13, 9]],
  "lega-lag": [[13, 3]],
  "wild-myth": [[13, 3]],
  "nrg-akim": [[13, 10]],
  "noun-fa": [[13, 6]],
  "part-phoe": [[13, 8]],
  "elev-limi": [[13, 6]],
  "e-xo-hom": [[13, 8]],
  "boss-flyt": [],
  "lega-boss": [[10, 13]],
  "noun-part": [[16, 13]],
  "wild-e-xo": [[13, 5]],
  "nrg-elev": [[13, 8]],
  "lag-flyt": [[7, 13], [10, 13]],
  "myth-hom": [[4, 13], [13, 6], [16, 13]],
  "akim-limi": [[13, 5], [16, 13]],
  "fa-phoe": [[13, 8], [13, 7]],
  "wild-boss": [[13, 11], [13, 7]],
  "nrg-noun": [[13, 10], [6, 13], [9, 13]],
  "lega-flyt": [[13, 10], [16, 19], [13, 1]],
  "part-fa": [[3, 13], [6, 13]],
  "elev-akim": [[13, 9], [13, 11]],
  "e-xo-myth": [[13, 9], [11, 13], [13, 3]],
  "nrg-lega": [[11, 13], [8, 13]],
  "boss-elev": [[13, 10], [11, 13], [19, 17]],
  "e-xo-fa": [[6, 13], [8, 13]],
  "red-case": [[11, 13]],
  "flux-kr": [[10, 13]],
  "oddi-galo": [[15, 19]],
  "shar-hype": [[13, 11]],
  "best-vkr": [[13, 3]],
  "insa-dr": [[13, 5]],
  "soli-inte": [[13, 11]],
  "bh-w7m": [[6, 13]],
  "best-galo": [[13, 9]],
  "insa-kr": [[7, 13]],
  "shar-w7m": [[5, 13]],
  "soli-case": [[6, 13]],
  "red-inte": [[11, 13], [13, 11], [13, 10]],
  "flux-dr": [[13, 7], [13, 8]],
  "oddi-vkr": [[11, 13], [13, 4], [13, 5]],
  "bh-hype": [[13, 16], [13, 10], [7, 13]],
  "case-w7m": [[13, 1], [13, 9]],
  "kr-best": [[13, 5], [4, 13], [8, 13]],
  "shar-oddi": [[13, 16], [9, 13]],
  "galo-hype": [[13, 7], [6, 13], [8, 13]],
  "red-soli": [[9, 13], [13, 9], [13, 0]],
  "flux-insa": [[13, 11], [13, 9]],
  "kr-hype": [[16, 13], [16, 13]],
  "red-oddi": [[13, 11], [13, 3]],
  "w7m-flux": [[9, 13], [4, 13]]}
